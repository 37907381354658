import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMainHeader } from "src/store/actions/global";
import useDeviceType from "src/utils/customHooks/useDeviceType";
import { DeviceType } from "src/components/shared/enums";
import DocumentCard from "src/components/cards/DocumentCard";
import { getVault, uploadDocument } from "src/store/actions/vault";
import { RootState } from "src/store/reducers";
import Spinner from "src/components/Spinner";
import IconTextButton from "src/components/buttons/IconTextButton";
import { hideModal, showModal } from "src/store/actions/modal";
import { MODAL_ADD_DOCUMENT } from "src/components/modals/modalTypes";
import { DocumentCardType, UploadDocumentType } from "src/services/Api/types";
import useInterval from "src/utils/customHooks/useInterval";

function VaultContent() {
  const dispatch = useDispatch();
  const deviceType = useDeviceType();
  const { isLoading, value: vault } = useSelector(
    ({ vault }: RootState) => (vault as any).vaultContent
  );
  const isDesktop = deviceType === DeviceType.Desktop;

  useEffect(() => {
    dispatch(
      setMainHeader({
        showMenuDrawerOnDesktop: true,
        title: isDesktop ? undefined : "Document Vault",
        showHamburgerButton: true,
        showNotificationsButton: true,
        showAddDocumentButton: !isDesktop,
      })
    );
    dispatch(getVault());
  }, [isDesktop]);

  useInterval(() => {
    dispatch(getVault());
  }, 30000);

  const showAddDocumentModal = () => {
    dispatch(
      showModal(MODAL_ADD_DOCUMENT, {
        onButtonClick: (document: UploadDocumentType) =>
          dispatch(uploadDocument(document)),
        onCloseButtonClick: () => dispatch(hideModal(MODAL_ADD_DOCUMENT)),
      })
    );
  };

  return (
    <div className="document-vault">
      {isLoading && !vault.COACard ? (
        <Spinner />
      ) : (
        <>
          <div className="document-vault-top">
            {vault.COACard && <DocumentCard document={{ ...vault.COACard }} />}
            {vault.MOACard && <DocumentCard document={{ ...vault.MOACard }} />}
          </div>
          <div className="document-vault-bottom">
            <div className="header-document-vault">
              {vault.otherDocuments && vault.otherDocuments.length !== 0 && (
                <h2>Other Documents</h2>
              )}
              {isDesktop && (
                <IconTextButton
                  onClick={() => showAddDocumentModal()}
                  text="Add new document"
                  iconClass="icon-plus"
                />
              )}
            </div>
            {vault.otherDocuments &&
              vault.otherDocuments.length !== 0 &&
              vault.otherDocuments.map((document: DocumentCardType, index: number) => (
                <DocumentCard key={index} document={document} />
              ))}
          </div>
        </>
      )}
    </div>
  );
}

export default VaultContent;
