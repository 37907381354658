import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import GeneralLayout from "./components/layout/GeneralLayout";
import IframeContainer from "./components/layout/IframeContainer";
import PrivateRoute from "./components/routing/PrivateRoute";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSession } from "./store/actions/auth";
import PublicRoute from "./components/routing/PublicRoute";
import Governance from "./pages/Governance";
import { Page } from "./utils/helpers/permissions";
import Onboarding from "./pages/Onboarding";
import LoginForm from "./pages/Onboarding/Login";
import LeaderSignUpForm from "./pages/Onboarding/signUp/Leader";
import MemberSignUpForm from "./pages/Onboarding/signUp/Member";
import CoOwners from "./pages/CoOwners";
import Profile from "./pages/Profile";
import Chat from "./pages/Chat";
import { Path } from "./components/routing/routes";
import Dashboard from "./pages/Dashboard";
import DocumentVault from "./pages/DocumentVault";
import SegmentAnalytics from "./components/SegmentAnalytics";
import { withProfiler } from "@sentry/react";
import AnalyticsEventsHandler from "./components/AnalyticsEventsHandler";
import { RootState } from "./store/reducers";
import { calculateUserHash } from "./utils/helpers/helpers";


function App() {
  const dispatch = useDispatch();
  const { session }: any = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(updateSession(true, false, true));
  }, []);

  useEffect(() => {
    let userHash = "";
    const userId = session?.user?.id || "";

    if (userId) {
      userHash = calculateUserHash(userId!);
    }

    if (session?.user?.email && userId) {
      window.intercomSettings.user_id = userId;
      window.intercomSettings.user_hash = userHash;
    }

  }, [session?.user?.id, session?.user?.email]);

  return (
    <GeneralLayout>
      <BrowserRouter>
        <Routes>
          <Route
            path={Path.Home}
            element={
              <PublicRoute>
                <LoginForm />
              </PublicRoute>
            }
          />
          <Route
            path={Path.PrivacyPolicy}
            element={
              <IframeContainer
                title="Privacy Policy"
                path="https://app.termly.io/document/privacy-policy/bb4032c9-e189-4c0b-9bed-2df80f579152"
              />
            }
          />
          <Route
            path={Path.Terms}
            element={
              <IframeContainer
                title="Terms and Conditions"
                path="https://app.termly.io/document/terms-of-use-for-saas/969b80e7-4cc9-4d1e-9b72-fe85689f3794"
              />
            }
          />
          <Route
            path={Path.Disclaimer}
            element={
              <IframeContainer
                title="Disclaimer"
                path="https://www.cobuy.io/disclaimer"
              />
            }
          />
          <Route
            path={Path.Login}
            element={
              <PublicRoute>
                <LoginForm />
              </PublicRoute>
            }
          />
          <Route
            path={Path.SignUp}
            element={
              <PublicRoute>
                <LeaderSignUpForm />
              </PublicRoute>
            }
          />
          <Route
            path={Path.Invitation}
            element={
              <PublicRoute>
                <MemberSignUpForm />
              </PublicRoute>
            }
          />

          <Route
            path={Path.Onboarding}
            element={
              <PrivateRoute permission={Page.ONBOARDING}>
                <Onboarding />
              </PrivateRoute>
            }
          />
          <Route
            path={Path.Profile}
            element={
              <PrivateRoute permission={Page.PROFILE}>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path={Path.Dashboard}
            element={
              <PrivateRoute permission={Page.DASHBOARD}>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={Path.DocumentVault}
            element={
              <PrivateRoute permission={Page.DOCUMENT_VAULT}>
                <DocumentVault />
              </PrivateRoute>
            }
          />
          <Route
            path={Path.Chat}
            element={
              <PrivateRoute permission={Page.CHAT}>
                <Chat />
              </PrivateRoute>
            }
          />
          <Route
            path={Path.CoOwners}
            element={
              <PrivateRoute permission={Page.CO_OWNERS}>
                <CoOwners />
              </PrivateRoute>
            }
          />
          <Route
            path={Path.Governance}
            element={
              <PrivateRoute permission={Page.GOVERNANCE}>
                <Governance />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate replace to={Path.Home} />} />
        </Routes>
        <SegmentAnalytics />
      </BrowserRouter>
      <AnalyticsEventsHandler />
    </GeneralLayout>
  );
}

export default withProfiler(App);
