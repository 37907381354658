//@ts-nocheck
import {
  call,
  put,
  select,
  take,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import {
  updateSessionSuccess,
  UpdateSessionAction,
  UPDATE_SESSION,
  LOGOUT,
  logoutSuccess,
  logoutRejected,
  AUTH_ERROR,
  UPDATE_SESSION_REJECTED,
  authError,
  HandleErrorsAction,
  HANDLE_ERRORS,
  handleErrors,
  updatePermissions,
  ACCESS_DASHBOARD,
  accessDashboardSuccess,
  accessDashboardRejected,
  ACCESS_GOVERNANCE,
  RESET_CSRF_TOKEN,
  resetCSRFTokenSuccess,
  resetCSRFTokenRejected,
  accessGovernanceSuccess,
  accessGovernanceRejected,
  updateSession,
} from "../actions/auth";
import { setFullPageLoading, setIsLoadingUserData } from "../actions/global";
import Api from "src/services/Api";
import {
  getPermissions,
  getDefaultPagePermission,
  Page,
  getDefaultPage,
  DashboardPermission,
} from "src/utils/helpers/permissions";
import { clearSession } from "../actions";
import { RootState } from "../reducers";
// import { SegmentEvents } from "src/components/shared/enums";
import { getPageFromPath } from "src/utils/helpers/routing";
import { userStartedGovernance } from "../actions/governance";
import { Session } from "../../services/Api/types";
import { captureMessage } from "@sentry/react";
import { analyticsReset, analyticsTrackEvent } from "../../components/SegmentAnalytics/utils";
import { SegmentEvents } from "../../components/shared/enums";

export default function* authSaga() {
  yield takeLatest(RESET_CSRF_TOKEN, resetCSRFTokenSaga);
  yield takeLatest(UPDATE_SESSION, updateSessionSaga);
  yield takeLatest(LOGOUT, logoutSaga);
  yield takeLeading(AUTH_ERROR, authErrorSaga);
  yield takeLatest(HANDLE_ERRORS, handleErrorsSaga);
  yield takeLatest(ACCESS_DASHBOARD, accessDashboardSaga);
  yield takeLatest(ACCESS_GOVERNANCE, accessGovernanceSaga);
}

// RESET CSRF TOKEN
export function* resetCSRFTokenSaga() {
  try {
    // Get CSRF token
    const { data } = yield call(Api.getCSRFToken);
    // Set CSRF token
    document
      .getElementsByName("csrf-token")[0]
      .setAttribute("content", data.xsrf_token);

    yield put(resetCSRFTokenSuccess());
  } catch (error) {
    captureMessage(JSON.stringify(error))
    yield put(resetCSRFTokenRejected());
  }
}

// UPDATE SESSION
export function* updateSessionSaga({ payload }: UpdateSessionAction) {
  try {
    // Show global loader
    if (payload.isLoadingUserData) {
      yield put(setIsLoadingUserData(true));
    } else if (payload.showFullPageLoader) {
      yield put(setFullPageLoading(true));
    }

    // // Get current session
    // const currentSession: Session = yield select((state: RootState) => state.auth.session);
    //
    // // Check if current session is defined
    // if (currentSession) {
    //   // Get session data
    //   const {
    //     data: { success, ...session },
    //   } = yield call(Api.getUserData);
    //   console.log({ session });
    // }

    // Get current session
    const currentSession: Session = yield select(({ auth }: RootState) => auth.session);

    // Get session data
    const {
      data: { success, ...session },
    } = yield call(Api.getUserData);
    console.log({session});


    // Reset CSRF token
    if (payload.isLoadingUserData) {
      yield call(resetCSRFTokenSaga);
    }

    // Redirect based on permissions
    if (payload.updatePermissions) {
      const permissions = getPermissions(session);
      const pageFromPath = getPageFromPath(window?.location?.pathname || "");
      const page = permissions[pageFromPath as Page]
        ? (pageFromPath as Page)
        : getDefaultPage(session);
      const permission = getDefaultPagePermission(page, session);
      yield put(updatePermissions({ permissions, page, permission }));
    }

    if (session?.user?.hasStartedCoAgreement && (!session?.user?.hasCompletedCoAgreement && currentSession?.user?.hasCompletedCoAgreement)) {
      yield put(userStartedGovernance());
    }

    // Update session succeed
    yield put(updateSessionSuccess(session));
  } catch (error: any) {
    // Update session failed
    yield put({ type: UPDATE_SESSION_REJECTED });

    // Wait error handler
    if (error.status === 401) yield take(handleErrors);
  } finally {
    // Hide global loader
    if (payload.isLoadingUserData) {
      yield put(setIsLoadingUserData(false));
    } else if (payload.showFullPageLoader) {
      yield put(setFullPageLoading(false));
    }
  }
}

//LOGOUT
function* logoutSaga() {
  try {
    // Show global loader
    yield put(setFullPageLoading(true));

    // Logout
    yield call(Api.logout);

    // Segment Analytics "logout"
    const session: boolean = yield select(({ auth }: RootState) => auth.session);
    yield call(analyticsTrackEvent, session, SegmentEvents.UserSignedOut);
    yield call(analyticsReset);

    // Logout succeed
    yield put(logoutSuccess());

    // Clean session
    yield put(clearSession());
  } catch (error) {
    captureMessage(JSON.stringify(error))
    // Logout failed
    yield logoutRejected();
  } finally {
    // Reset CSRF token
    yield call(resetCSRFTokenSaga);

    // Hide global loader
    yield put(setFullPageLoading(false));
  }
}

// AUTHENTICATION ERROR
function* authErrorSaga() {
  yield put(setFullPageLoading(true));

  // Clean session
  yield put(clearSession());

  // Reset CSRF token
  yield call(resetCSRFTokenSaga);
}

// HANDLE ERRORS
function* handleErrorsSaga({ payload }: HandleErrorsAction) {
  if (payload === 401) {
    yield put(authError());
  }
}

// ACCESS DASHBOARD
function* accessDashboardSaga() {
  try {
    const userHasAccessToDashboard: boolean = yield select(
      ({ auth }: RootState) => auth.session?.user?.hasAccessDashboard
    );

    if (!userHasAccessToDashboard) {
      const { data } = yield call(Api.accessDashboard);
      const { success, ...session } = data;

      // Get permissions
      const permissions = getPermissions(session);
      const page = getDefaultPage(session);
      const permission = getDefaultPagePermission(page, session);

      // Update session
      yield put(updateSessionSuccess(session));
      yield put(updatePermissions({ permissions, page, permission }));
    } else {
      yield put(
        updatePermissions({
          page: Page.DASHBOARD,
          permission: DashboardPermission.DASHBOARD,
        })
      );
    }
    // Access dashboard succeed
    yield put(accessDashboardSuccess());
  } catch (error) {
    captureMessage(JSON.stringify(error))
    // Access dashboard failed
    yield put(accessDashboardRejected());
  }
}

// ACCESS GOVERNANCE
function* accessGovernanceSaga() {
  try {
    yield call(updateSessionSaga, updateSession(false, false, true));

    // Access dashboard succeed
    yield put(accessGovernanceSuccess());
  } catch (error) {
    captureMessage(JSON.stringify(error))
    // Access dashboard failed
    yield put(accessGovernanceRejected());
  }
}
