import { PADDLE_SANDBOX, PADDLE_VENDOR_ID } from "src/constants";

export const initiatePaddle = () => {
  if (PADDLE_SANDBOX) {
    window.Paddle.Environment.set("sandbox");
  }
  window.Paddle.Setup({
    vendor: PADDLE_VENDOR_ID,
  });
};
