import {
  AgreementSectionStatusType,
  AgreementStepStatusType,
  FormSectionStepConfirmationRequestType,
  FormSectionStepType,
  SectionType
} from "src/components/shared/types";
import { getInitialFieldsOptions } from "src/utils/helpers/governance";
import {
  FieldsValidationType,
  RESET_INITIAL_STEP_ANSWERS,
  ResetInitialStepAnswersAction,
  SET_AGREEMENT_SECTIONS_STATUS_SUCCESS,
  SET_AGREEMENT_STEPS_STATUS_SUCCESS,
  SET_ALL_STEPS_COMPLETED,
  SET_CURRENT_SECTION,
  SET_CURRENT_STEP_STATUS_SUCCESS,
  SET_EDITION_MODE,
  SET_FIELDS_VALIDATION,
  SET_IS_STEP_LOADING,
  SET_SECTIONS,
  SET_STEP_ANSWER,
  SET_STEP_VALIDATION,
  SetAgreementSectionsStatusSuccessAction,
  SetAgreementStepsStatusSuccessAction,
  SetAllStepsCompletedAction,
  SetCurrentSectionAction,
  SetCurrentStepStatusSuccessAction,
  SetEditionModeAction,
  SetFieldsValidationAction,
  SetIsStepLoadingAction,
  SetSectionsAction,
  SetStepAnswerAction,
  SetStepValidationAction,
  USER_COMPLETED_GOVERNANCE,
  USER_COMPLETED_GOVERNANCE_RECEIVED,
  USER_STARTED_GOVERNANCE,
  USER_STARTED_GOVERNANCE_RECEIVED,
  UserCompletedGovernanceAction,
  UserCompletedGovernanceReceivedAction,
  UserStartedGovernanceAction,
  UserStartedGovernanceReceivedAction,
  ValidationType
} from "../actions/governance";

export const initialState: {
  onEditionMode: boolean;
  isStepLoading: boolean;
  currentSection?: SectionType;
  sections?: Array<SectionType>;
  currentSectionStep?: FormSectionStepType | null;
  previousSectionStep?: string | null;
  allStepsCompleted?: boolean | null;
  stepAnswer?: FormSectionStepConfirmationRequestType;
  fieldsValidation?: FieldsValidationType;
  stepValidation?: ValidationType;
  agreementSectionsStatus?: AgreementSectionStatusType;
  agreementStepsStatus?: AgreementStepStatusType;
  alreadySawGuide: boolean;
  onLastModalSeen: string;
  userCompletedGovernance: boolean;
  userStartedGovernance: boolean;
} = {
  userCompletedGovernance: false,
  userStartedGovernance: false,
  onEditionMode: false,
  isStepLoading: false,
  alreadySawGuide: false,
  onLastModalSeen: ""
};

export function governanceReducer(
  state = initialState,
  action:
    | SetEditionModeAction
    | SetCurrentStepStatusSuccessAction
    | SetSectionsAction
    | SetCurrentSectionAction
    | SetIsStepLoadingAction
    | SetStepAnswerAction
    | SetFieldsValidationAction
    | ResetInitialStepAnswersAction
    | SetStepValidationAction
    | SetAgreementSectionsStatusSuccessAction
    | SetAgreementStepsStatusSuccessAction
    | SetAllStepsCompletedAction
    | UserCompletedGovernanceReceivedAction
    | UserCompletedGovernanceAction
    | UserStartedGovernanceAction
    | UserStartedGovernanceReceivedAction
) {
  switch (action.type) {
    case RESET_INITIAL_STEP_ANSWERS:
      return {
        ...state,
        stepAnswer: {
          ...state.stepAnswer,
          fields: getInitialFieldsOptions(
            state.currentSectionStep?.formSectionStepFields
          )
        }
      };
    case SET_EDITION_MODE:
      return { ...state, onEditionMode: action.payload };
    case SET_CURRENT_SECTION:
      return { ...state, currentSection: action.payload };
    case SET_SECTIONS:
      return { ...state, sections: action.payload };
    case SET_STEP_ANSWER:
      return { ...state, stepAnswer: action.payload };
    case SET_CURRENT_STEP_STATUS_SUCCESS:
      return {
        ...state,
        currentSectionStep: action.payload.currentSectionStep,
        previousSectionStep: action.payload.previousSectionStep,
        allStepsCompleted: action.payload.allStepsCompleted,
        alreadySawGuide: !action.payload.previousSectionStep
          ? state.alreadySawGuide
          : true
      };
    case SET_ALL_STEPS_COMPLETED:
      return {
        ...state,
        allStepsCompleted: action.payload
      };
    case SET_IS_STEP_LOADING:
      return { ...state, isStepLoading: action.payload };
    case SET_FIELDS_VALIDATION:
      const fieldsValidation = action.payload
        ? { ...state.fieldsValidation, ...action.payload }
        : undefined;
      return {
        ...state,
        fieldsValidation
      };
    case SET_STEP_VALIDATION:
      return { ...state, stepValidation: action.payload };
    case SET_AGREEMENT_SECTIONS_STATUS_SUCCESS:
      return { ...state, agreementSectionsStatus: action.payload };
    case SET_AGREEMENT_STEPS_STATUS_SUCCESS:
      return { ...state, agreementStepsStatus: action.payload };
    case USER_COMPLETED_GOVERNANCE:
      return { ...state, userCompletedGovernance: true};
    case USER_COMPLETED_GOVERNANCE_RECEIVED:
      return { ...state, userCompletedGovernance: false};
    case USER_STARTED_GOVERNANCE:
      return { ...state, userStartedGovernance: true};
    case USER_STARTED_GOVERNANCE_RECEIVED:
      return { ...state, userStartedGovernance: false};
    default:
      return state;
  }
}
