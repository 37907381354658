import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CoOwnersStep, DeviceType, SegmentEvents } from "src/components/shared/enums";
import { CoOwner } from "src/services/Api/types";
import { updateSession } from "src/store/actions/auth";
import { setCoOwnersStep, setSelectedCoOwner } from "src/store/actions/coOwners";
import { setMainHeader } from "src/store/actions/global";
import { getPendingConsensusActions, signAddCoOwner, signDeleteCoOwner } from "src/store/actions/signature";
import { RootState } from "src/store/reducers";
import useDeviceType from "src/utils/customHooks/useDeviceType";
import useInterval from "src/utils/customHooks/useInterval";
import AddNewCoOwner from "./AddNewCoOwner";
import CoOwnersList from "./CoOwnersList";
import CoOwnerStatus from "./CoOwnerStatus";
import { getCoOwnersAndInvitedUsers } from "./getCoOwnersAndInvitedUsers";
import Signature from "./Signature";
import { AnalyticsContext, AnalyticsContextType } from "../../components/Analytics";

function CoOwners() {
  const dispatch = useDispatch();
  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType;
  // @ts-ignore
  const session = useSelector(({ auth }: RootState) => auth.session);
  const deviceType = useDeviceType();
  const { step, selectedCoOwner } = useSelector(
    ({ coOwners }: RootState) => coOwners
  );
  const { eventId, invitedUserEmail, invitedUserFirstName, userIdToRemove } =
    useSelector(
      ({ signature }: RootState) => signature.coOwners.pendingConsensusActions
    );

  useEffect(() => {
    dispatch(updateSession(false, true, true));
    dispatch(getPendingConsensusActions());
    return () => {
      dispatch(setCoOwnersStep(CoOwnersStep.CoOwners));
      dispatch(setSelectedCoOwner(null));
    };
  }, []);
  const isMobile = deviceType === DeviceType.Mobile;
  useEffect(() => {
    dispatch(
      setMainHeader({
        showMenuDrawerOnDesktop: true,
        showLogo: step !== CoOwnersStep.CoOwners,
        title:
          step === CoOwnersStep.CoOwners
            ? selectedCoOwner
              ? "Co-owner status"
              : "Co-owners"
            : undefined,
        showHamburgerButton: step === CoOwnersStep.CoOwners && !selectedCoOwner,
        showNotificationsButton: true,
        onBackButtonClick:
          step === CoOwnersStep.CoOwners
            ? !!selectedCoOwner && isMobile
              ? () => dispatch(setSelectedCoOwner(null))
              : undefined
            : step === CoOwnersStep.FillNewCoOwnerData && isMobile
              ? () => dispatch(setCoOwnersStep(CoOwnersStep.CoOwners))
              : step === CoOwnersStep.Signature && isMobile
                ? !!userIdToRemove || !!eventId
                  ? () => dispatch(setCoOwnersStep(CoOwnersStep.CoOwners))
                  : () => dispatch(setCoOwnersStep(CoOwnersStep.FillNewCoOwnerData))
                : undefined
      })
    );
  }, [step, selectedCoOwner, deviceType]);

  useInterval(() => {
    if (step === CoOwnersStep.CoOwners) {
      dispatch(updateSession(false, false, true));
      dispatch(getPendingConsensusActions());
    }
  }, 30000);

  const onCoOwnerClick = (coOwner: CoOwner) => {
    dispatch(setSelectedCoOwner(coOwner));
  };

  if (!session?.group || !session.user) return <></>;

  const coOwnersAndInvitedUsers = getCoOwnersAndInvitedUsers(
    session.group,
    session.user
  );
  const isDeleteAction = !!userIdToRemove;
  const userToDelete = coOwnersAndInvitedUsers.find(
    (user) => user.id === userIdToRemove
  );
  return step === CoOwnersStep.CoOwners ? (
    selectedCoOwner ? (
      <CoOwnerStatus
        coOwner={selectedCoOwner!}
        coOwners={coOwnersAndInvitedUsers}
      />
    ) : (
      <CoOwnersList
        user={session.user}
        coOwners={coOwnersAndInvitedUsers}
        onCoOwnerClick={onCoOwnerClick}
      />
    )
  ) : step === CoOwnersStep.FillNewCoOwnerData ? (
    <AddNewCoOwner
      backButton={
        deviceType === DeviceType.Desktop
          ? {
            onBackButtonClick: () =>
              dispatch(setCoOwnersStep(CoOwnersStep.CoOwners))
          }
          : undefined
      }
    />
  ) : step === CoOwnersStep.Signature ? (
    <Signature
      title={isDeleteAction ? "Remove co-owner" : "Add co-owner"}
      description={`Please sign to ${
        isDeleteAction
          ? `remove <b>${userToDelete?.firstName} ${userToDelete?.lastName}</b> (${userToDelete?.email}) from`
          : `add <b>${invitedUserFirstName}</b> (${invitedUserEmail}) to`
      } your group `}
      onButtonClick={() => {
        if (eventId && (userToDelete || invitedUserEmail)) {
          if (isDeleteAction) {
            dispatch(signDeleteCoOwner({ eventId, userIdToDelete: userToDelete?.id! }));
            if (session?.group?.allMemberSignedCoAgreement) {
              segmentTrack(
                SegmentEvents.RemoveUserSigned, {
                  user_id: session?.user?.id,
                  user_to_group_id: session?.userToGroupId
                }, { groupId: session?.group?.id }
              );
            }

          } else {
            dispatch(signAddCoOwner({
              eventId,
              userEmailToInvite: invitedUserEmail!
            }));
          }
        }
      }}
      backButton={
        deviceType === DeviceType.Desktop
          ? {
            onBackButtonClick:
              !!userIdToRemove || !!eventId
                ? () => dispatch(setCoOwnersStep(CoOwnersStep.CoOwners))
                : () =>
                  dispatch(
                    setCoOwnersStep(CoOwnersStep.FillNewCoOwnerData)
                  )
          }
          : undefined
      }
    />
  ) : (
    <></>
  );
}

export default CoOwners;
