import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Button from "src/components/buttons/Button";
import { MainHeaderType, setMainHeader } from "src/store/actions/global";
import { Path } from "src/components/routing/routes";
import InitialStepHeader from "./InitialStepHeader";

type InitialStepProps = {
  title?: string;
  boldTitle?: string;
  titleAfterBold?: string;
  description?: string;
  text?: string;
  primaryButtonText: string;
  isPrimaryButtonLoading?: boolean;
  isPrimaryButtonDisabled?: boolean;
  onPrimaryButtonClick: (step?: number) => void;
  secondaryButtonText?: string;
  onSecondaryButtonClick?: (step?: number) => void;
  loginLink?: React.ReactNode;
  iconClass?: string;
  progress?: number;
  progressIcon?: string;
  mainHeaderProps?: Partial<MainHeaderType>;
};

function InitialStep({
  title,
  boldTitle,
  titleAfterBold,
  description,
  text,
  onPrimaryButtonClick,
  isPrimaryButtonLoading,
  isPrimaryButtonDisabled,
  primaryButtonText,
  secondaryButtonText,
  onSecondaryButtonClick,
  loginLink = false,
  iconClass,
  progress,
  progressIcon,
  mainHeaderProps = { hideHeaderOnMobile: true, showLogo: true },
}: InitialStepProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMainHeader(mainHeaderProps));
  }, []);

  return (
    <div className="content-wrapper">
      <div className="vertical-centered">
        <InitialStepHeader
          iconClassName={iconClass}
          title={title}
          boldTitle={boldTitle}
          titleAfterBold={titleAfterBold}
          description={description}
        />

        {progress !== undefined && (
          <div className="progress-wrapper">
            <div className="spinner " />

            {progressIcon && <i className={progressIcon}></i>}
            <p>{`${progress}%`}</p>
          </div>
        )}

        <div className="bottom-fixed have-comment">
          <p className="upper-text">{text}</p>

          <Button
            text={primaryButtonText}
            onClick={onPrimaryButtonClick}
            isLoading={isPrimaryButtonLoading}
            isDisabled={isPrimaryButtonDisabled}
          />

          {onSecondaryButtonClick && secondaryButtonText && (
            <Button
              className="secondary-button"
              text={secondaryButtonText}
              onClick={onSecondaryButtonClick}
              isDisabled={isPrimaryButtonLoading}
            />
          )}

          {loginLink && (
            <p className="bottom-text link-bold">
              Already have an account? <Link to={Path.Login}>Log in</Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default InitialStep;
