import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  getNotificationStatus,
  setNotificationsDrawer,
} from "src/store/actions/notifications";
import { RootState } from "src/store/reducers";
import useInterval from "src/utils/customHooks/useInterval";
import IconButton from "./IconButton";

function NotificationsButton() {
  const dispatch = useDispatch();
  const { notificationsDrawer, notifications } = useSelector(
    ({ notifications }: RootState) => notifications
  );

  useEffect(() => {
    dispatch(setNotificationsDrawer(false, false));
    return () => {
      dispatch(setNotificationsDrawer(false, true));
    };
  }, []);

  useEffect(() => {
    if (notificationsDrawer.isOpened) dispatch(getNotifications());
    else dispatch(getNotificationStatus());
  }, [notificationsDrawer.isOpened]);

  useInterval(() => {
    !notificationsDrawer.isHidden &&
      !notificationsDrawer.isOpened &&
      dispatch(getNotificationStatus());
  }, 30000);

  const openCloseNotificationsDrawer = () =>
    dispatch(setNotificationsDrawer(!notificationsDrawer.isOpened));

  return (
    <IconButton
      iconClassName="icon-bell"
      className="notifications-button"
      isDisabled={false}
      onClick={openCloseNotificationsDrawer}
      hasNotifications={notifications.notificationsToRead}
    />
  );
}

export default NotificationsButton;
